<template>
  <div class="page404">
    <div class="page404__content">
      <div class="label">Ошибка</div>
      <div class="code">404</div>
      <div v-if="isCertificateError" class="message">
        При получении сертификата возникла ошибка,
        <br>
        свяжитесь с администратором.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  computed: {
    isCertificateError() {
      return this.$route.query.cert !== undefined
    }
  }
}
</script>

<style scoped lang="scss">
.page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);

  &__content {

    .label {
      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 33px;

      text-align: center;

      color: #1454F2;

      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }

    .code {
      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 111px;
      line-height: 130px;
      text-align: center;

      color: #1454F2;

      @media screen and (max-width: 600px) {
        font-size: 80px;
      }
    }

    .message {
      margin-top: 50px;

      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 47px;

      text-align: center;

      color: #1D262D;

      @media screen and (max-width: 1200px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media screen and (max-width: 850px) {
        font-size: 26px;
        line-height: 32px;
      }
      @media screen and (max-width: 700px) {
        font-size: 22px;
        line-height: 26px;
      }
      @media screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
        br {
          display: none!important;
        }
      }
    }

  }

}
</style>
